
import { defineComponent } from 'vue';
import TopNav from '@/components/top-nav.vue';

export default defineComponent({
  name: 'App',
  components: {
    TopNav
  },
  data() {
    return {
      mp4BackgroundVideo: require('@/assets/koga-splash.mp4'),
      webmBackgroundVideo: require('@/assets/koga-splash.webm'),
      imgBackgrounVideo: require('@/assets/koga-splash.gif')
    };
  }
});
