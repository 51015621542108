
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'TopNav',
  data() {
    return {
      isOpen: false,
      logo: require('@/assets/koga-eclipse-logo.gif'),
      links: [
        { label: 'About', link: '/' },
        { label: 'Structure', link: '/structure' },
        { label: 'Videos', link: '/videos' },
        { label: 'Games', link: '/games' },
        { label: 'Recruitment', link: '/recruitment' }
      ]
    };
  },
  computed: {
    mobileMenuD(): string {
      return this.isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16';
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    }
  }
});
